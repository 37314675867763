<template>
	<!--flow整体配置-->
	<div class="wholeHtml">
		<TForm ref="refFrom1" lableRow  :autoCheck='true' :model="model" :formlist="formlist" :rowGutter='16' labelPosition='left'>
			<template slot="timeoutSet" slot-scope="scope">
				<el-input v-model.number="model[scope.current.keys]" placeholder='请输入'  
					onkeyup="value=value.replace(/\D+/g, '')" />
				<!--<el-input v-model="model[scope.current.keys]"  placeholder='请输入'  type="number" min="1" step="1" />-->
				<span class="tipTx">分钟</span>
			</template>
				
			
			<!--<el-input v-model="_ic.value"  oninput="value=value.replace(/[^\d^\.]+/g,'')" />-->
		
		</TForm>
	</div>
</template>

<script>
	import TForm from '@/components/YTable/TForm.vue';
	export default {
		components: { TForm },
		props: {
			model:{ //表单数据对象
	            type:Object,
	            default:()=>{
	                return {}
	            },
	        },
			type: {
				type: String,
				default: '1',
			},
			
		},
		watch:{
			type: {
				deep: true, // 深度监听
				handler(val, oval) {}
			},
			'model.dueOpen': {
				deep: true, // 深度监听
				handler(val, oval) {
					this.showHideItems(val);
					
				}
			},
		},
		computed: {},
		data() {
			return {
				htmlDatas: {},
				formlist: [
					{name: '超时提醒',keys: 'dueOpen',value: 'N',type: 'switch',flex:24},
					{name: '到期时间类型',keys: 'dueType',value: 'PF',type: 'select',diyClass:'hideRow',options:[
						//到期时间类型 （PH:小时，PD:天，PW:周，PM: 月）  当前需求隐藏
//						{name:'小时',code:'PH'},
						{name:'分钟',code:'PF'},
					]},
					{name: '超时设置',keys: 'dueTime',value: '12',type: 'slot',slotName:"timeoutSet",isMust:true,tips:'分钟',diyClass:'setTimeinp',isHide:false},
					{name: '提醒方式',keys: 'notifyMethod',value: ['0'],type: 'check',flex:24,isHide:false,options:[
	  					// 通知方式(0:待办、1：消息、2：短信、3邮件)
	  					{name:'待办',code:'0',disabled:true},
						{name:'消息',code:'1'},
						{name:'短信',code:'2'},
						{name:'邮件',code:'3'},
	  				]},
	  				
	  				{name: '相邻节点审批人相同时',keys: 'sameApproverOperation',value: 'PASS',diyClass:'borderTop',type: 'checkD',isHide:false,options:[
						{name:'直接通过',code:'PASS'},
						{name:'顺序审批',code:'APPROVE'}
					]},
				],
			};
		},
		created() {
			this.resetTFrom();
		},
		mounted() {},
		methods: {
			showHideItems(val){
				let hide=false;
				if(val=='N'){
					hide=true;
				}
				let keyArr=Object.keys(this.model);
				if(keyArr.length<1){
					this.resetTFrom();
				}else{
					this.formlist.map(it=>{
						if(it.name!=='超时提醒'&&it.name!=='相邻节点审批人相同时') it.isHide=hide;
//						if(it.name=='超时设置'){
//							if(hide){
//								it.isMust=false;
//							}else{
//								it.isMust=true;
//							}
//						}
					})
				}
				
			},
			resetTFrom(){
	  			for(let item of this.formlist) { //初始化赋值我的表单数据
					
					if(!this.model[item.keys]){
						this.$set(this.model, item.keys, item.value);
					}
				}
	  			this.showHideItems(this.model.dueOpen);
	  		},
		}
		
	};
</script>

<style>
	.setTimeinp{
		position: relative;
		.el-input__inner{
			width: calc(100% - 30px);
		}
		.tipTx{
			color: #252D3D;
	        position: absolute;
	        left: 90%;
	        top: 44px;
	        width: 30px;
		}
	}
	.hideRow{
		display: none;
	}
	.wholeHtml{
		.el-checkbox{
			margin-right:12px
		}
		.el-checkbox__label{
			padding-left: 6px;
		}
		.borderTop .el-form-item {
			padding-top: 12px;
			border-top: 1px solid #E3E8EE;
		}
	} 
</style>